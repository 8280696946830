import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
import { firestore } from './../firebase-config';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import MenuItem from "@material-ui/core/MenuItem";
import Slider from "react-slick";
import { id_firebase } from '../content-site';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Button } from '@material-ui/core';

function VeiculosRecentes(data) {
    const veiculos = data.veiculos;
    const listVeiculos = veiculos.map((v) =>
        <div className="item" key={v.id}>
            <div className="car-item text-center">
                <div className="car-image">
                    {
                        v.imagens.length > 0 ?
                            v.imagens[0].fileThumb !== undefined ?
                                <img className="img-fluid" src={v.imagens[0].fileThumb} alt=""></img>
                                :
                                <img className="img-fluid" src={v.imagens[0].fileUrl} alt=""></img>
                            : <img className="img-fluid" src="/img/car_default.png" alt='imagem veículo'></img>
                    }
                    <div className="car-overlay-banner">
                        <ul>
                            <li><Link to={"/veiculo/" + v.id}><i className="fa fa-link"></i></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="car-list">
                    <ul className="list-inline">
                        <li><i className="fa fa-registered"></i>{v.veano_fabricacao}/{v.vefipe_ano_modelo}</li>
                        <li><i className="fa fa-cog"></i>{formatCambio(v.vecambio)}</li>
                        <li><i className="fa fa-dashboard"></i>{v.vequilometragem} KM</li>
                    </ul>
                </div>
                <div className="car-content">
                    <span>{v.veano_fabricacao}/{v.vefipe_ano_modelo}</span>
                    <Link to={"/veiculo/" + v.id}>{v.vefipe_marca} {v.vefipe_name}</Link>
                    <div className="separator"></div>
                    <div className="price">
                        <span className="new-price">{formatMoney(v.vevalor)}</span>
                    </div>
                </div>
            </div>
        </div>
    );

    return <OwlCarousel className='owl-theme' autoplay={true} loop={true} items={2} margin={30} nav>{listVeiculos}</OwlCarousel>

}


function ImagensLoja(data) {

    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        accessibility: true
    };

    const imagens = data.images;
    const listImagens = imagens.map((key) =>
        <div key={key}><img className="img-responsive center-block" src={key} alt={"Banner " + data.revenda.penome_fantasia} /></div>
    );
    return <Slider {...settings}>{listImagens}</Slider>;

}

class Inicio extends Component {

    constructor(props) {
        super(props);

        this.state = {
            veiculos: [],
            veiculosComplete: [],
            countVeiculos: 0,
            instagramData: [],
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
            revenda: {},
            bannerData: []
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
            .get()
            .then(doc => {
                if (doc.exists) {
                    this.setState({
                        revenda: doc.data(),
                    });
                } else {
                    this.setState({
                        revenda: {
                            ativo: 0,
                        }
                    });
                }
            }).catch(function (error) {
            });

        firestore.collection("revenda").doc(id_firebase).collection("veiculos").where("veremovido", "==", 0).where("vevendido", "==", 0).orderBy("updated_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document["veexibir_site"] !== false) {
                        items.push(document);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        veiculos: items,
                        veiculosComplete: items,
                        countVeiculos: items.length
                    });
                }
            });

        firestore.collection("revenda").doc(id_firebase).collection("imagem_destaque").orderBy("created_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    items.push(document);
                });
                if (this.mounted) {
                    this.setState({
                        instagramData: items
                    });
                }
            });

        firestore.collection("revenda").doc(id_firebase).collection("banner_site").orderBy("created_at", "desc")
            .onSnapshot(querySnapshot => {
                var items = [];
                querySnapshot.forEach(doc => {
                    var document = doc.data();
                    document.id = doc.id;
                    if (document.imagem_url !== undefined && document.imagem_url.trim().length > 0) {
                        items.push(document.imagem_url);
                    }
                });
                if (this.mounted) {
                    this.setState({
                        bannerData: items
                    });
                }
            });

    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleChange(event) {

        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        let filtro = this.state.buscaVeiculo.toLowerCase();
        let tipoVeiculo = this.state.tipoVeiculo;

        let veiculosFilter = this.state.veiculosComplete.filter(function (veiculo) {
            let descricaoVeiculo = veiculo.vefipe_marca.toLowerCase() + " " + veiculo.vefipe_name.toLowerCase();
            if (tipoVeiculo === 'novos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem <= 100;
            }
            if (tipoVeiculo === 'seminovos') {
                return descricaoVeiculo.includes(filtro) && veiculo.vequilometragem > 100;
            }
            return descricaoVeiculo.includes(filtro);
        });

        this.setState({
            veiculos: veiculosFilter
        });

    }

    handleClick(event) {
        this.setState({
            veiculos: this.state.veiculosComplete,
            buscaVeiculo: '',
            tipoVeiculo: 'todos',
        });
    }

    render() {
        const {
            veiculos,
            instagramData,
            tipoVeiculo,
            bannerData
        } = this.state;

        if (this.state.revenda.ativo === 0) {
            return (
                <div style={{ textAlign: "center" }}>
                    <h1>Site em manutenção.</h1>
                    <h3>Contate o suporte para mais detalhes (suporte@intermedioveiculos.com.br)</h3>
                </div>
            )
        }

        return (
            <div>
                <Cabecalho revenda={this.state.revenda} />

                <div
                    id="rev_slider_6_1_wrapper"
                    className="rev_slider_wrapper fullwidthbanner-container rev_slider_6_1_wrapper"
                    data-alias="slider-7"
                >

                    <div
                        id="rev_slider_6_1"
                        className="rev_slider fullwidthabanner"
                        style={{ display: "none" }}
                        data-version="5.2.6"
                    >
                        <ul>
                        <li
                                data-index="rs-3"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="revolution/assets/100x50_3ecde-01.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="revolution/assets/slider-8/mainbg2.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption tp-resizeme slide-3-layer-1"
                                    id="slide-3-layer-1"
                                    data-x="62"
                                    data-y="100"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="text"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":500,"speed":1500,"frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]"
                                    style={{color: "#FFF", fontSize: 55, lineHeight: "7rem"}}
                                >
                                    RS Motors <br />
                                    Seu 0KM está aqui!!!<br />
                                    
                                </div>

                                <div
                                    className="tp-caption tp-resizeme slide-3-layer-2"
                                    id="slide-3-layer-2"
                                    data-x="62"
                                    data-y="250"
                                    data-width="['657']"
                                    data-height="['auto']"
                                    data-type="text"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":1070,"frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]"
                                    style={{color: "#FFF", fontSize: 35, lineHeight: "4rem"}}
                                >
                                    Novos e Seminovos<br/>
                                    com garantia.
                                </div>

                                <div
                                    className="tp-caption button red slide-3-layer-4"
                                    id="slide-3-layer-4"
                                    data-x="62"
                                    data-y="452"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>

                            <li
                                data-index="rs-4"
                                data-transition="random-static,random-premium,random"
                                data-slotamount="default,default,default,default"
                                data-hideafterloop="0"
                                data-hideslideonmobile="off"
                                data-randomtransition="on"
                                data-easein="default,default,default,default"
                                data-easeout="default,default,default,default"
                                data-masterspeed="default,default,default,default"
                                data-thumb="revolution/assets/100x50_ac5dd-02.jpg"
                                data-rotate="0,0,0,0"
                                data-saveperformance="off"
                                data-title="Slide"
                                data-param1=""
                                data-param2=""
                                data-param3=""
                                data-param4=""
                                data-param5=""
                                data-param6=""
                                data-param7=""
                                data-param8=""
                                data-param9=""
                                data-param10=""
                                data-description=""
                            >
                                <img
                                    src="revolution/assets/slider-8/mainbg3.jpg"
                                    alt=""
                                    data-bgposition="center center"
                                    data-bgfit="cover"
                                    data-bgrepeat="no-repeat"
                                    className="rev-slidebg"
                                    data-no-retina
                                />

                                <div
                                    className="tp-caption tp-resizeme slide-4-layer-1"
                                    id="slide-4-layer-1"
                                    data-x="62"
                                    data-y="100"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="text"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":500,"speed":1500,"frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]"
                                    style={{color: "#FFF", fontSize: 55, lineHeight: "7rem"}}
                                >
                                    uma equipe<br /> de profissionais<br />
                                    com atendimento<br /> personalizado
                                </div>

                                {/* <div
                                    className="tp-caption tp-resizeme slide-4-layer-2"
                                    id="slide-4-layer-2"
                                    data-x="62"
                                    data-y="250"
                                    data-width="['657']"
                                    data-height="['auto']"
                                    data-type="text"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":1070,"frame":"0","from":"x:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[0,0,0,0]"
                                    data-paddingright="[0,0,0,0]"
                                    data-paddingbottom="[0,0,0,0]"
                                    data-paddingleft="[0,0,0,0]"
                                    style={{color: "#FFF", fontSize: 35, lineHeight: "4rem"}}
                                >
                                    VEÍCULOS VERIFICADOS <br />
                                    E CERTIFICADOS
                                </div> */}

                                <div
                                    className="tp-caption button red slide-4-layer-4"
                                    id="slide-4-layer-4"
                                    data-x="62"
                                    data-y="430"
                                    data-width="['auto']"
                                    data-height="['auto']"
                                    data-type="button"
                                    data-responsive_offset="on"
                                    data-frames='[{"delay":1720,"speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"nothing"},{"frame":"hover","speed":"300","ease":"Linear.easeNone","force":true,"to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(0, 0, 0, 1.00);bg:rgba(255, 255, 255, 1.00);bs:solid;bw:0 0 0 0;"}]'
                                    data-textalign="['left','left','left','left']"
                                    data-paddingtop="[10,10,10,10]"
                                    data-paddingright="[30,30,30,30]"
                                    data-paddingbottom="[10,10,10,10]"
                                    data-paddingleft="[30,30,30,30]"
                                ></div>
                            </li>
                        </ul>
                        <div
                            className="tp-bannertimer tp-bottom"
                            style={{ visibility: "hidden !important" }}
                        ></div>
                    </div>
                </div>

                <section className="feature-car white-bg page-section-ptb">
                    <div className="col-md-12">
                        <div className="section-title">
                            <span>Confira nosso estoque</span>
                            <h2>Veículos recentes</h2>
                            <div className="separator"></div>
                        </div>
                    </div>
                </section>

                <section className="product-listing page-section-ptb" style={{ paddingTop: "10px" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-8">
                                <ValidatorForm id="contactForm" className="s-form wow zoomInUp" ref="form" onSubmit={this.handleSubmit} >
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="col-lg-6">
                                                <TextValidator placeholder="Ex.: Fiat Palio"
                                                    label="Encontre seu veículo"
                                                    onChange={this.handleChange}
                                                    name="buscaVeiculo"
                                                    value={this.state.buscaVeiculo}
                                                    className="form-ui-bootstrap" />

                                            </div>

                                            <div className="col-lg-3" style={{ paddingTop: "27px" }}>
                                                <SelectValidator
                                                    value={tipoVeiculo}
                                                    name="tipoVeiculo"
                                                    className="form-ui-bootstrap select-busca-veiculos"
                                                    onChange={this.handleChange}>
                                                    <MenuItem value="todos">Todos</MenuItem>
                                                    <MenuItem value="novos">0 Km</MenuItem>
                                                    <MenuItem value="seminovos">Seminovos</MenuItem>
                                                </SelectValidator>
                                            </div>

                                            <div className="col-lg-3 text-center">
                                                <button type="submit" className="btn m-btn">BUSCAR VEÍCULOS<span className="fa fa-angle-right"></span></button><br />
                                                <p className="linkLimparBusca text-center" onClick={this.handleClick}>LIMPAR BUSCA</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/*
                                    <div className="row sorting-options">
                                        <div className="col-lg-5">
                                            <div className="change-view-button">
                                                <a className="active" href="#"> <i className="fa fa-th"></i> </a>
                                                <a href="listing-02.html"> <i className="fa fa-list-ul"></i> </a>
                                            </div>
                                        </div> 
                                        
                                    </div>
*/}
                                </ValidatorForm>

                                <div className="row">
                                    {Object.keys(veiculos)
                                        .map(key => {
                                            return (
                                                <div className="col-lg-4" data-wow-delay="0.5s" key={veiculos[key].id}>
                                                    <div className="car-item gray-bg text-center">
                                                        <Link to={"/veiculo/" + veiculos[key].id}>
                                                            <div className="car-image">
                                                                {
                                                                    veiculos[key].imagens.length > 0 ?
                                                                        veiculos[key].imagens[0].fileThumb !== undefined ?
                                                                            <img className="img-fluid" src={veiculos[key].imagens[0].fileThumb} alt=""></img>
                                                                            :
                                                                            <img className="img-fluid" src={veiculos[key].imagens[0].fileUrl} alt=""></img>
                                                                        : <img className="img-fluid" src="/images/car_default.png" alt='imagem veículo'></img>
                                                                }

                                                                <div className="car-overlay-banner">
                                                                    
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <div className="car-list">
                                                            <ul className="list-inline">
                                                                <li><i className="fa fa-registered"></i>{this.state.veiculos[key].veano_fabricacao}/{this.state.veiculos[key].vefipe_ano_modelo}</li>
                                                                <li><i className="fa fa-cog"></i>{formatCambio(veiculos[key].vecambio)}</li>
                                                                <li><i className="fa fa-dashboard"></i>{veiculos[key].vequilometragem} KM</li>
                                                            </ul>
                                                        </div>
                                                        <div className="car-content">
                                                            <span>{veiculos[key].veano_fabricacao}/{veiculos[key].vefipe_ano_modelo}</span>
                                                            <Link to={"/veiculo/" + veiculos[key].id}>{veiculos[key].vefipe_marca} {veiculos[key].vefipe_name}</Link>
                                                            <div className="separator"></div>
                                                            <div className="price">
                                                                <span className="new-price">{formatMoney(veiculos[key].vevalor)}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="why-choose-us bg-2 bg-overlay-black-70 page-section-ptb">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <span className="text-white">Algumas informações</span>
                                    <h2 className="text-white">Estrutura da loja</h2>
                                    <div className="separator"></div>
                                </div>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-4">
                                <div className="feature-box-2 box-hover active">
                                    <div className="icon">
                                        <i className="glyph-icon flaticon-beetle"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Todas as marcas</h5>
                                        <p>
                                            Encontre o carro que você procura com facilidade, aqui na
                                            RS Motors você pode encontrar varias opções de veículos
                                            novos e seminovos.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature-box-2 box-hover active">
                                    <div className="icon">
                                        <i className="glyph-icon flaticon-interface-1"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Atendimento</h5>
                                        <p>Confira nossas redes sociais e nosso atendimento online!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="feature-box-2 box-hover active">
                                    <div className="icon">
                                        <i className="glyph-icon flaticon-key"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Venda seu veículo</h5>
                                        <p>
                                            Com a avaliação justa no seu veículo e pagamento a vista,
                                            consulte um de nossos vendedores.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="feature-box-2 box-hover active">
                                    <div className="icon">
                                        <i className="glyph-icon flaticon-wallet"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Financiamento</h5>
                                        <p>
                                            Em parceria com os principais bancos e financeiras, buscamos
                                            sempre as menores taxas de juros para nosso clientes.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="feature-box-2 box-hover active">
                                    <div className="icon">
                                        <i className="glyph-icon flaticon-gearstick"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Blindados</h5>
                                        <p>
                                            Para você que procura maior segurança fornecemos veículos
                                            novos, usados e blindados.
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-4">
                                <div className="feature-box-2 box-hover active">
                                    <div className="icon">
                                        <i className="glyph-icon flaticon-gas-station"></i>
                                    </div>
                                    <div className="content">
                                        <h5>Garantia</h5>
                                        <p>
                                            Veículos com garantia e um rigoroso processo seletivo antes de
                                            ser anunciado.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="testimonial-1 white-bg page page-section-ptb">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <span>Nossa loja</span>
                                    <h2>Veja um pouco da loja</h2>
                                    <div className="separator"></div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div
                                    className="owl-carousel owl-theme"
                                    data-nav-dots="true"
                                    data-nav-arrow="false "
                                    data-items="3"
                                    data-md-items="3"
                                    data-sm-items="2"
                                    data-xs-items="1"
                                    data-space="20"
                                >
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/01.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Em nossa loja, você encontra veículos 0 KM com preços e condições especiais e seminovos da melhor qualidade possível, com a garantia da RS Motors. 
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/02.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Comercializar automóveis multimarcas com compromisso, honestidade e preço justo. Atender necessidades, superar expectativas, realizar sonhos. Buscar um bom relacionamento com colaboradores, fornecedores e clientes.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/03.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    O sucesso nasce do querer, da determinação e persistência em se chegar a um objetivo. 
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/04.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nossos clientes e amigos Simone, Luciano e Gabriela pela preferência e confiança em nosso trabalho e parabenizá-los pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/05.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nosso cliente e amigo Priscila, pela preferência e confiança em nosso trabalho e parabenizá-lo pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/06.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nosso cliente e amigo Sidvan, pela preferência e confiança em nosso trabalho e parabenizá-lo pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/07.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nosso cliente e amigo Pereira, pela preferência e confiança em nosso trabalho e parabenizá-lo pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/08.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nossos clientes e amigos Weverson e amigos, pela preferência e confiança em nosso trabalho e parabenizá-los pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/09.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nossos clientes e amigos Roberto e Alaíde, pela preferência e confiança em nosso trabalho e parabenizá-los pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimonial-block text-center">
                                            <div className="testimonial-image">
                                                <img
                                                    className="img-fluid"
                                                    src="images/testimonial/10.jpg"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="testimonial-box">
                                                <div className="testimonial-content">
                                                    <p>
                                                    Gostaríamos de agradecer nosso cliente e amigo Paulo, pela preferência e confiança em nosso trabalho e parabenizá-lo pela compra. Desejamos sucesso com o novo veiculo e volte sempre.
                                                    </p>
                                                    <i className="fa fa-quote-right"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="quick-links white-bg">
                    <div className="container">
                        <div className="tuor_virtual">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <span>Onde estamos localizados</span>
                                        <h2>Encontre nossa loja</h2>
                                        <div className="separator"></div>
                                    </div>
                                </div>
                            </div>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3774.563644274836!2d-48.270834799999996!3d-18.906429699999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94a4456f44c7e3eb%3A0x7a9ebf1ca18e6876!2sRS%20Motors!5e0!3m2!1spt-BR!2sbr!4v1660935114241!5m2!1spt-BR!2sbr"  width="100%" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </section>

                <Rodape revenda={this.state.revenda} />
            </div >
        )
    }
}

function formatMoney(value) {

    var places = 2;
    var symbol = "R$ ";
    var thousand = ".";
    var decimal = ",";

    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ",";
    decimal = decimal || ".";
    var number = value,
        negative = number < 0 ? "-" : "",
        i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
    return symbol + negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "");
};

function formatCambio(value) {
    if (value === 'A') {
        return "Automático";
    } else {
        return "Manual";
    }
}

export default Inicio