import React, { Component } from 'react';
import Cabecalho from '../componentes/Cabecalho';
import Rodape from '../componentes/Rodape';
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import { id_firebase } from '../content-site';
import { firestore } from './../firebase-config';

const BootstrapInput = withStyles(theme => ({
    input: {
      borderRadius: 30,
      border: '1px solid #eeeeee',
      font: "400 10px 'Open Sans', sans-serif",
      width: '94% !important',
      padding: '12px 4% !important',
    },
  }))(InputBase);

class Financiamento extends Component {

    constructor (props){
        super(props);

        this.state = {
            dados_financiamento: '',
            cpf: '',
            nome: '',
            data_nascimento: '',
            rg: '',
            sexo: '',
            estado_civil: '',
            naturalidade: '',
            naturalidade_uf: '',
            telefone: '',
            celular: '',
            nome_pai: '',
            nome_mae: '',
            cep_residencial: '',
            endereco: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            cidade_uf: '',
            empresa_trabalha: '',
            cargo: '',
            tempo_servico: '',
            com_cep: '',
            com_endereco: '',
            com_numero: '',
            com_complemento: '',
            com_bairro: '',
            com_cidade: '',
            com_uf: '',
            ref1_nome: '',
            ref1_telefone: '',
            ref2_nome: '',
            ref2_telefone: '',
            banco1_banco: '',
            banco1_agencia: '',
            banco1_conta: '',
            banco1_tempo_conta: '',
            banco2_banco: '',
            banco2_agencia: '',
            banco2_conta: '',
            banco2_tempo_conta: '',
            enviado: false,
            msg_enviado: '',
            revenda: {},
        }
        this.mounted = false;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount(){

        window.scrollTo(0, 0);

        this.mounted = true;

        firestore.collection("revenda").doc(id_firebase)
        .get()
        .then(doc => {
            if (doc.exists) {
                this.setState({
                    revenda: doc.data(),
                });
            }
        }).catch(function(error) {
        });

    }

    handleChange(event) {

        const target = event.target;
        const value  = target.value;
        const name   = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {

        event.preventDefault();
        
        this.setState({enviado:true, msg_enviado: 'Enviando mensagem...'});

        var mensagem = "<strong>Dados do financiamento:</strong><br><br>"+ this.state.dados_financiamento;
            mensagem += "<br><br><strong>Dados pessoais</strong><br>";
            mensagem += "<br>CPF: "+ this.state.cpf;
            mensagem += "<br>Nome: "+ this.state.nome;
            mensagem += "<br>Data de nascimento: "+ this.state.data_nascimento;
            mensagem += "<br>RG: "+ this.state.rg;
            mensagem += "<br>Sexo: "+ this.state.sexo;
            mensagem += "<br>Estado civil: "+ this.state.estado_civil;
            mensagem += "<br>Naturalidade: "+ this.state.naturalidade + "-" + this.state.naturalidade_uf;
            mensagem += "<br>Telefone: "+ this.state.telefone;
            mensagem += "<br>Celular: "+ this.state.celular;
            mensagem += "<br>Nome do pai: "+ this.state.nome_pai;
            mensagem += "<br>Nome da mãe: "+ this.state.nome_mae;
            mensagem += "<br>CEP residencial: "+ this.state.cep_residencial;
            mensagem += "<br>Endereço: "+ this.state.endereco + ", " + this.state.numero + " " + this.state.complemento;
            mensagem += "<br>Bairro: "+ this.state.bairro;
            mensagem += "<br>Cidade: "+ this.state.cidade + "-" + this.state.cidade_uf;

            mensagem += "<br><br><strong>Dados comerciais</strong><br>";
            mensagem += "<br>Empresa que trabalha: "+ this.state.empresa_trabalha;
            mensagem += "<br>Cargo: "+ this.state.cargo;
            mensagem += "<br>Tempo de serviço: "+ this.state.tempo_servico;
            mensagem += "<br>CEP da empresa: "+ this.state.com_cep;
            mensagem += "<br>Endereço: "+ this.state.com_endereco + ", " + this.state.com_numero + " " + this.state.com_complemento;
            mensagem += "<br>Bairro: "+ this.state.com_bairro;
            mensagem += "<br>Cidade: "+ this.state.com_cidade + "-" + this.state.com_uf;

            mensagem += "<br><br><strong>Referências pessoais</strong><br>";
            mensagem += "<br>Nome referência 01: "+ this.state.ref1_nome;
            mensagem += "<br>Telefone referência 01: "+ this.state.ref1_telefone;
            mensagem += "<br>Nome referência 02: "+ this.state.ref2_nome;
            mensagem += "<br>Telefone referência 02: "+ this.state.ref2_telefone;

            mensagem += "<br><br><strong>Referências bancárias</strong><br>";
            mensagem += "<br>Banco 01: "+ this.state.banco1_banco;
            mensagem += "<br>Agência banco 01: "+ this.state.banco1_agencia;
            mensagem += "<br>Conta banco 01: "+ this.state.banco1_conta;
            mensagem += "<br>Tempo de conta banco 01: "+ this.state.banco1_tempo_conta;
            mensagem += "<br>Banco 02: "+ this.state.banco2_banco;
            mensagem += "<br>Agência banco 02: "+ this.state.banco2_agencia;
            mensagem += "<br>Conta banco 02: "+ this.state.banco2_conta;
            mensagem += "<br>Tempo de conta banco 02: "+ this.state.banco2_tempo_conta;
            
       
        fetch('https://us-central1-intermedio-1513801146022.cloudfunctions.net/enviarEmail', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify({
                remetente: "Contato " + this.state.revenda.penome_fantasia + "<" + this.state.revenda.peemail + ">",
                assunto: "Simulação de Financiamento - Site " + this.state.revenda.penome_fantasia,
                destinatarios: this.state.revenda.peemail,
                corpo: mensagem,
                corpoHtml: mensagem
            })
        })
        .then(data => {
            this.setState({
                enviado: true, 
                msg_enviado: 'E-Mail enviado com sucesso, em breve você receberá um retorno da nossa equipe.',
                dados_financiamento: '',
                cpf: '',
                nome: '',
                data_nascimento: '',
                rg: '',
                sexo: '',
                estado_civil: '',
                naturalidade: '',
                naturalidade_uf: '',
                telefone: '',
                celular: '',
                nome_pai: '',
                nome_mae: '',
                cep_residencial: '',
                endereco: '',
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                cidade_uf: '',
                empresa_trabalha: '',
                cargo: '',
                tempo_servico: '',
                com_cep: '',
                com_endereco: '',
                com_numero: '',
                com_complemento: '',
                com_bairro: '',
                com_cidade: '',
                com_uf: '',
                ref1_nome: '',
                ref1_telefone: '',
                ref2_nome: '',
                ref2_telefone: '',
                banco1_banco: '',
                banco1_agencia: '',
                banco1_conta: '',
                banco1_tempo_conta: '',
                banco2_banco: '',
                banco2_agencia: '',
                banco2_conta: '',
                banco2_tempo_conta: '',
            });
            setInterval(() => this.setState({enviado:false}), 5000);
        })
        .catch(error => {
            this.setState({enviado:true, msg_enviado: 'Erro ao enviar e-mail, tentar novamente mais tarde'});
            setInterval(() => this.setState({enviado:false}), 5000);
        });

    }

    render(){
        return(
            <div>
                <Cabecalho revenda={this.state.revenda} />

                <section>
                    <div className="container">
                        <div className="row text-center intro-title">
                            <div className="col-md-12 text-md-left d-inline-block">
                                <h1 className="text-white">FINANCIAMENTO</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="form-financiamento">
                    <section className="b-contacts s-shadow">
                        <div className="container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="b-contacts__form ">
                                        <header className="b-contacts__form-header s-lineDownLeft wow zoomInUp" data-wow-delay="0.5s">
                                            <h2 className="s-titleDet">Solicite uma simulação de financiamento</h2> 
                                        </header>
                                        <p className=" wow zoomInUp" data-wow-delay="0.5s">Envie seus dados para simularmos um financimento. Em breve retornaremos o contato.</p>
                                        <ValidatorForm id="contactForm" className="s-form wow zoomInUp" ref="form" onSubmit={this.handleSubmit} >
                                        <h3>VEÍCULO DE INTERESSE</h3>
                                            <div className="row">
                                                <div className="col-xs-12">
                                                    <textarea name="dados_financiamento" placeholder="DESCREVA OS DADOS VEÍCULO QUE VOCÊ DESEJA FINANCIAR, BEM COMO O VALOR DO MESMO E AS CONDIÇÕES DO FINANCIAMENTO (ENTRADA, PARCELAS, TROCA DE VEÍCULO, ETC.)" onChange={this.handleChange} ></textarea>
                                                </div>
                                            </div>
                                            <h3>DADOS PESSOAIS</h3>
                                            <div className="row dadospessoais">
                                                <div className="col-xs-3">
                                                    <InputMask
                                                        mask="999.999.999-99"
                                                        value={this.state.cpf}
                                                        label="CPF"
                                                        name="cpf"
                                                        onChange={this.handleChange}
                                                        >
                                                        {() => <TextField
                                                        label="CPF"
                                                        name="cpf"
                                                        type="text"
                                                        className="form-ui-bootstrap"
                                                        required
                                                        />}
                                                    </InputMask>
                                                </div>
                                                <div className="col-xs-6">
                                                    <TextValidator
                                                        label="NOME COMPLETO *"
                                                        onChange={this.handleChange}
                                                        name="nome"
                                                        value={this.state.nome}
                                                        className="form-ui-bootstrap"
                                                        validators={['required']}
                                                        errorMessages={['Preenchimento obrigatório']}
                                                    />
                                                </div>
                                                <div className="col-xs-3">
                                                    <InputMask
                                                        mask="99/99/9999"
                                                        value={this.state.data_nascimento}
                                                        label="DATA DE NASCIMENTO"
                                                        name="data_nascimento"
                                                        onChange={this.handleChange}
                                                        >
                                                        {() => <TextField
                                                        label="DATA DE NASCIMENTO"
                                                        name="data_nascimento"
                                                        type="text"
                                                        className="form-ui-bootstrap"
                                                        required
                                                        />}
                                                    </InputMask>
                                                </div>
                                                <div className="col-xs-8">
                                                    <TextValidator
                                                        label="CIDADE"
                                                        onChange={this.handleChange}
                                                        name="com_cidade"
                                                        value={this.state.com_cidade}
                                                        className="form-ui-bootstrap"
                                                        validators={['required']}
                                                    />
                                                </div>
                                                <div className="col-xs-3">
                                                    <InputMask
                                                        mask="(99) 99999-9999"
                                                        value={this.state.ref1_telefone}
                                                        label="TELEFONE *"
                                                        name="ref1_telefone"
                                                        onChange={this.handleChange}
                                                        >
                                                        {() => <TextField
                                                        label="TELEFONE (WHATSAPP) *"
                                                        name="ref1_telefone"
                                                        type="text"
                                                        className="form-ui-bootstrap"
                                                        required
                                                        />}
                                                    </InputMask>
                                                </div>
                                            </div>
                                            
                                            
                                            
                                            <button type="submit" className="btn m-btn">ENVIAR DADOS<span className="fa fa-angle-right"></span></button>
                                            {
                                                this.state.enviado &&
                                                <blockquote>
                                                    <p><em>{ this.state.msg_enviado }</em></p>
                                                </blockquote>
                                            }
                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Rodape revenda={this.state.revenda} />
            </div>
        )
    }
}

export default Financiamento