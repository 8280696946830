import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Rodape extends Component {

  constructor(props) {
    super(props);
  }

  data_atual = new Date();

  render() {
    return (
      <footer className="footer footer-black bg-2 bg-overlay-black-90">
        <div className="container">
          <div className="row">
            <a className='whatsappFlutuante' href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") } target='_blank' >
              <i className="fa fa-whatsapp"></i>
            </a>
            <div className="col-md-12">
              <div className="social">
                <ul>
                  <li>
                    <a href={ this.props.revenda.facebook_link } target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook-square"></i></a>
                  </li>
                  <li>
                    <a href={ this.props.revenda.instagram_link } target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" target="_blank"></i></a>
                  </li>
                  <li>
                    <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") }><i className="fa fa-whatsapp"></i></a>
                  </li>
                  <li>
                    <a target="_blank" href={ this.props.revenda.mapa_link } className="wow slideInLeft" rel="noopener noreferrer" ><i className="fa fa-map-marker"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row footer_info">
            <div className="col-lg-3 col-md-6 footer_conteudo">
              <div className="about-content">
                <img
                  className="img-fluid"
                  id="logo-footer"
                  src="images/logo-light.png"
                  alt=""
                />
                <p>
                Veículos novos e seminovos com procedência e qualidade, nosso objetivo é alcançar a satisfação do cliente com veículos selecionados, 
                bom atendimento e taxas de financiamento competitivas faça um visita a nossa loja e conheça nosso amplo estoque.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footer_conteudo">
              <div className="usefull-link">
                <h6 className="text-white">Informações de contato</h6>
                  <ul className="text-sobrenos-info">
                    <li>
                      <a target="_blank" href={ this.props.revenda.mapa_link } className="wow slideInLeft" rel="noopener noreferrer" >
                        { this.props.revenda.peendereco_fiscal } - { this.props.revenda.peendereco_fiscal_numero } - { this.props.revenda.pebairro_fiscal } - { this.props.revenda.pecidade } - { this.props.revenda.peuf }
                      </a>
                    </li>
                    <li>
                      <a href={ "tel:" + retiraMascara(this.props.revenda.petelefone1, "link") } >
                        <i className="fa fa-phone"></i>
                        { retiraMascara(this.props.revenda.petelefone1) }
                      </a>
                    </li>
                    <li>
                    <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") }>
                      <i className="fa fa-whatsapp"></i>
                      { retiraMascara(this.props.revenda.petelefone2) }
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href={ 'https://api.whatsapp.com/send?text=Olá, vi um anúncio em seu site e gostaria de saber mais sobre o veículo ?&phone=55'+retiraMascara(this.props.revenda.petelefone3, "link") }>
                        <i className="fa fa-whatsapp"></i>
                        { retiraMascara(this.props.revenda.petelefone3) }
                        </a>
                    </li>
                    <li>
                      <i className="fa fa-envelope-o"> </i>
                      <a href={ "mailto:" + this.props.revenda.peemail }>{ this.props.revenda.peemail }</a>
                    </li>
                  </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="text-lg-left text-center">
                  <p>
                    ©Copyright 2022 RS Motors por
                    <a
                      href="https://intermedioveiculos.com.br/"
                      target="_blank"
                    > Intermédio Veículos Marketing Digital</a
                    >
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-12">
                <ul className="list-inline text-lg-right text-center">
                  <li><a href="contact-02.html">Contato </a></li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

function retiraMascara(value, type = "texto") {
  var numero = '';
  if (value !== undefined) {
    numero = value.replace('_', '');
    if (type === "link") {
      numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    }
  }
  return numero;
}

export default Rodape;