import React, { Component } from 'react';
import { Link } from 'react-router-dom';


function retiraMascara(value, type = "texto"){
    var numero = '';
    if(value !== undefined){
        numero = value.replace('_', '');
        if(type === "link"){
            numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        }
    }
    return numero;
}


class Cabecalho extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        /*   console.log(this.props.revenda); */

        return (

            <header id="header" className="topbar-dark logo-center">
                <div className="topbar">
                    <div className="container">
                        <div className="row menu_info">
                            <div className="col-md-6 menu_info_1">
                                <div className="topbar-left text-md-left text-center">
                                    <ul className="list-inline">
                                        <li>
                                            <i className="fa fa-envelope-o"> </i>
                                            <a href={ "mailto:" + this.props.revenda.peemail }>{ this.props.revenda.peemail }</a>
                                        </li>
                                        <li>
                                            <i className="fa fa-clock-o"></i> { this.props.revenda.horario_seg_sex } { this.props.revenda.horario_sab }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 menu_info_2">
                                <div className="topbar-right text-md-right text-center">
                                    <ul className="list-inline">
                                        <li>
                                            <a href={ "tel:" + retiraMascara(this.props.revenda.petelefone1, "link") } >{ retiraMascara(this.props.revenda.petelefone1) }</a>
                                        </li>
                                        <li>
                                          <a href={ this.props.revenda.facebook_link } target="_blank" rel="noopener noreferrer"><span className="fa fa-facebook-square"></span></a>
                                        </li>
                                        <li>
                                          <a href={ this.props.revenda.instagram_link } target="_blank" rel="noopener noreferrer"><span className="fa fa-instagram" target="_blank"></span></a>
                                        </li>
                                        <li>
                                            <a target="_blank" href={ this.props.revenda.mapa_link } className="wow slideInLeft" rel="noopener noreferrer" ><i className="fa fa-map-marker"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="menu">
                    <nav id="menu" className="mega-menu">
                        <section className="menu-list-items">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <ul className="menu-logo">
                                            <li>
                                                <a href="index.html"
                                                ><img
                                                        id="logo_dark_img"
                                                        src="images/logo-dark.png"
                                                        alt="logo"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                        <ul className="menu-links">
                                            <li><Link to="/" > INÍCIO</Link></li>
                                            <li><Link to="/estoque" > ESTOQUE</Link></li>
                                            <li><Link to="/vendaseuveiculo" > VENDA SEU VEÍCULO</Link></li>
                                            <li><Link to="/financiamento" > FINANCIAMENTO</Link></li>
                                            <li><Link to="/contato" > CONTATO</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </nav>
                </div>
            </header>


        )
    }

}

export default Cabecalho;